import React from "react";

const SplitBanner = ({
  images = [],
  title,
  imageHeight = "50vh",
  reverse = false,
}) => {
  return (
    <div className="container p-0">
      <div className="row g-2">
        {images.map((image, index) => (
          <div
            key={index}
            className={`col-md-${
              images.length === 1
                ? "12"
                : images.length === 2
                  ? reverse
                    ? index === 0
                      ? "4"
                      : "8"
                    : index === 0
                      ? "8"
                      : "4"
                  : "4"
            }`}
          >
            <img
              src={image}
              alt={`${title} - ${index + 1}`}
              className="img-fluid w-100 h-100 object-fit-md-cover object-fit-contain medium-min-height"
              style={{ minHeight: imageHeight }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default SplitBanner;
