import "aos/dist/aos.css";
import React, { lazy, memo, Suspense, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useInView } from "react-intersection-observer";
import SimpleLoader from "../components/Loader/SimpleLoader";
import useWishlist from "../Hooks/useWishlist";
import Slider from "../components/Home/slider";
import SplitBanner from "../components/Home/SplitBanner";
import offer1 from "../images/separate_banner/3.jpg";
import offer2 from "../images/separate_banner/4.jpg";
import offer3 from "../images/separate_banner/5.jpg";
import offer4 from "../images/separate_banner/6.jpg";
import offer5 from "../images/separate_banner/7.jpg";
import offer6 from "../images/separate_banner/8.jpg";
import offer7 from "../images/separate_banner/10.jpg";
import offer8 from "../images/separate_banner/11.jpg";
import offer9 from "../images/separate_banner/12.jpg";
import offer10 from "../images/separate_banner/14.jpg";
import offer11 from "../images/separate_banner/15.jpg";
import offer12 from "../images/separate_banner/16.jpg";

const HomeBanner = lazy(() => import("../components/Home/HomeBanner"));
// const OfferHome = lazy(() => import("../components/Home/OfferHome"));
const BestsellingHome = lazy(
  () => import("../components/Home/BestsellingHome")
);
const FeaturedProducts = lazy(
  () => import("../components/Home/FeaturedProdcuts")
);
const DownloadApp = lazy(() => import("../components/Home/DownloadApp"));
// const PlatniumMembrForm = lazy(() =>
//   import("../components/Home/PlatniumMembrForm")
// );

const bannerData = [
  {
    images: [offer1, offer2, offer3],
    title: "Discover Our Beer Collection",
  },
  {
    images: [offer4, offer5],
    title: "Celebrate with Champagne",
    maxHeight: "60vh",
  },
  {
    images: [offer6],
    title: "Explore Our Wine Selection",
  },
  {
    images: [offer7, offer8],
    title: "Premium Vodka Selection",
    reverse: true,
  },
  {
    images: [offer9, offer10],
    title: "Premium Vodka Selection",
  },
  {
    images: [offer11, offer12],
    title: "Premium Vodka Selection",
    reverse: true,
  },
];

const Home = () => {
  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  const { GetWishlistData } = useWishlist();
  const wishLIstData = GetWishlistData();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Best Online Liquor Store in Abu Dhabi | Royal Spirit</title>
        <meta
          name="description"
          content="Discover Abu Dhabi's top online liquor shop. From timeless classics to rare finds, Royal Spirit offers the best in beer, spirit and wine selections. Shop now!"
        />
        <link rel="canonical" href="https://royalspirit.ae/" />
      </Helmet>
      <HomeBanner />
      <BestsellingHome wishlistItems={wishLIstData} key="beslitl-322" />
      <section
        className="total-body d-block py-md-4 pt-3"
        ref={ref}
        style={{ minHeight: "2400" }}
      >
        {inView && (
          <Suspense fallback={<SimpleLoader />}>
            <FeaturedProducts
              wishlistItems={wishLIstData}
              key="FeaturedPds-32"
              category="beer"
              title="Beer Deals: Premium Brews at Great Prices"
            />
            <section className="total-body d-block py-md-4 py-3">
              <SplitBanner {...bannerData[0]} />
            </section>
            <FeaturedProducts
              wishlistItems={wishLIstData}
              key="FeaturedPds-37"
              category="spirit/whiskey-and-burbon"
              title="Whiskey & Bourbon: Premium Spirits on Sale"
            />
            <section className="total-body d-block py-md-4 py-3">
              <Slider />
            </section>
            <FeaturedProducts
              wishlistItems={wishLIstData}
              key="FeaturedPds-34"
              category="wine"
              title="Wine Deals: Fine Varieties for Less"
            />
            <section className="total-body d-block py-md-4 py-3">
              <SplitBanner {...bannerData[1]} />
            </section>
            <FeaturedProducts
              wishlistItems={wishLIstData}
              key="FeaturedPds-35"
              category="spirit/vodka"
              title="Vodka Specials: Unmatched Quality at Great Prices"
            />
            <section className="total-body d-block py-md-4 py-3">
              <SplitBanner {...bannerData[2]} />
            </section>
            <FeaturedProducts
              wishlistItems={wishLIstData}
              key="FeaturedPds-36"
              category="spirit/tequila"
              title="Tequila Deals: Iconic Spirits for Less"
            />
            <section className="total-body d-block py-md-4 py-3">
              <SplitBanner {...bannerData[3]} />
            </section>
            <FeaturedProducts
              wishlistItems={wishLIstData}
              key="FeaturedPds-33"
              category="champagne"
              title="Champagne: Celebrate in Style for Less"
            />
            <section className="total-body d-block py-md-4 py-3">
              <SplitBanner {...bannerData[4]} />
            </section>
            <FeaturedProducts
              wishlistItems={wishLIstData}
              key="FeaturedPds-38"
              category="spirit/rum"
              title="Rum Offers: Premium Picks at Great Value"
            />
            <section className="total-body d-block py-md-4 py-3">
              <SplitBanner {...bannerData[5]} />
            </section>
            <FeaturedProducts
              wishlistItems={wishLIstData}
              key="FeaturedPds-39"
              category="spirit/brandy-and-cognac"
              title="Cognac & Brandy: Exquisite Deals Await"
            />
            {/* <PlatniumMembrForm /> */}
            <DownloadApp />
          </Suspense>
        )}
      </section>
    </>
  );
};

export default memo(Home);
