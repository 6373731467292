import React from "react";

const features = [
  "Fastest Delivery in Abu Dhabi in 60 Minutes",
  "Get Free Delivery on Orders Above 100 AED",
];

const Slider = () => {
  return (
    <div className="slider-scroll">
      <div className="slider-container">
        {[...features, ...features].map((feature, index) => (
          <div key={index} className="fw-bold gap-2 text-white py-2">
            <span className="fw-bold fs-1 text-uppercase">{feature}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Slider;
