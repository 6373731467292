import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  fetchCartData,
  createCartData,
  updateCartData,
  deleteCartData,
  fetchProductDetailData,
} from "../http/apiService";
import useWishlist from "./useWishlist";
import { useCallback, useMemo } from "react";

function useCart() {
  const dispatch = useDispatch();
  const { removeFromWishlist } = useWishlist();
  const userId = useSelector((state) => state.user.User_Data.user_id);
  const cartItems = useSelector((state) => state.cart.CartItems);
  const auth_token = useSelector((state) => state.user.User_Data.auth_token);

  const header = useMemo(
    () => ({
      headers: {
        Authorization: `Bearer ${auth_token}`,
      },
    }),
    [auth_token]
  );

  //! cart functions
  const getCartItems = useCallback(async () => {
    if (!auth_token) {
      console.warn("No auth token provided. Skipping fetch.");
      return;
    }

    try {
      const response = await fetchCartData(userId, header);

      if (response.status === 200 || response.status === 201) {
        const cartData = response.data?.data || [];
        const isNotFound = response.data?.status === 404;

        dispatch({
          type: "GET_CART_ITEM",
          payload: isNotFound ? [] : cartData,
        });
      } else {
        console.warn(`Unexpected response status: ${response.status}`);
      }

      return response;
    } catch (error) {
      console.error("Error fetching cart data:", error);
      return null; // Return null to signify an error occurred
    }
  }, [auth_token, userId, header, dispatch]);

  const createCart = async (formData) => {
    try {
      const response = await createCartData(formData, header);
      if (response.status === 200 || response.status === 201) {
        if (response.data.status === 404) {
          toast.warn(response.data.message, {
            autoClose: 800,
            theme: "dark",
            toastId: "CartNotice",
          });
        } else {
          toast.success("Product Added to Cart!", {
            autoClose: 800,
            theme: "dark",
            toastId: "CartNotice",
          });
          getCartItems();
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      return error;
    }
  };

  const removeCartItem = async (id) => {
    try {
      const response = await deleteCartData(id, header);
      if (response.status === 200 || response.status === 201) {
        toast.success("Product Successfully Removed from Cart!", {
          autoClose: 800,
          theme: "dark",
          toastId: "CartNotice",
        });
        getCartItems();
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      return error;
    }
  };

  function GetCartData() {
    return cartItems?.cart;
  }

  function GetCartLength() {
    return cartItems?.cart?.length || 0;
  }

  function isCart() {
    return !!(cartItems?.cart?.length > 0);
  }

  async function addtoCart(formData, is_wish_id, isPageCart, setIsAddToCart) {
    let updatedCartData;
    if (!auth_token) {
      toast.warn("Please Login First!", {
        autoClose: 800,
        theme: "dark",
        toastId: "CartAuthNotice",
      });
    } else {
      if (isPageCart) {
        const { data } = await fetchProductDetailData(formData?.id);
        if (data?.product?.price_variation[0]?.stock === 0) {
          toast.warn("Can't add to cart, item is out of stock", {
            autoClose: 800,
            theme: "dark",
            toastId: "CartOutofStockNotice",
          });
          setIsAddToCart(null);
          return;
        }
        updatedCartData = {
          user_id: formData?.user_id,
          product_id: data?.product?.price_variation[0]?.product_id,
          product_variation_id: data?.product?.price_variation[0]?.variation_id,
          product_value_id:
            data?.product?.price_variation[0]?.variation_value_id,
          qty: 1,
        };
        createCart(updatedCartData);
        setIsAddToCart(null);
        return;
      }
      createCart(formData);
      if (is_wish_id) {
        removeFromWishlist(is_wish_id, true);
      }
    }
  }

  async function updateCart(cart_id, qty) {
    try {
      const cartItemsResponse = await getCartItems();
      const cartData = cartItemsResponse?.data?.data?.cart?.map((item) => ({
        cart_id: item?.cart_id,
        qty: item?.cart_id === cart_id ? qty : item?.qty,
      }));

      const payload = {
        user_id: userId,
        cart: cartData,
      };

      const response = await updateCartData(payload, header);
      if (response.status === 200 || response.status === 201) {
        getCartItems();
      }
      return response;
    } catch (error) {
      toast.error("Oops, Something went wrong!", {
        autoClose: 800,
        theme: "dark",
        toastId: "CartNotice",
      });
      console.error("Error fetching data:", error);
      return error;
    }
  }

  function CartItemsSum() {
    return cartItems?.total;
  }

  return {
    addtoCart,
    getCartItems,
    GetCartData,
    updateCart,
    removeFromCart: removeCartItem,
    clearCart: getCartItems,
    GetCartLength,
    CartItemsSum,
    isCart,
  };
}

export default useCart;
