import React, { useState, useEffect } from "react";
import logo from "../../images/logo/logo.png";
import { toast } from "react-toastify";
import { Modal, Container, Row, Col, Form } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import { useDispatch } from "react-redux";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import OtpInput from "react-otp-input";
import { resendOTP, userLogin, verifyOTP } from "../../http/apiService";
import { logIn } from "../../appRedux/actions/userAction";
import { useNavigate } from "react-router-dom";
import SocialLoginComponent from "./SocialLoginComponent";

const Login = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [mobile, setMobile] = useState("");
  const [otp, setOtp] = useState("");
  const [errors, setErrors] = useState({});
  const [isResendDisabled, setIsResendDisabled] = useState(true);
  const [resendTimer, setResendTimer] = useState(30);

  // Reset state when modal is reopened
  useEffect(() => {
    if (!props.show) {
      setMobile("");
      setOtp("");
      setErrors({});
      setStep(1);
      setIsResendDisabled(true);
      setResendTimer(30);
    }
  }, [props.show]);

  // Resend OTP timer
  useEffect(() => {
    let timer;
    if (step === 2 && isResendDisabled) {
      timer = setInterval(() => {
        setResendTimer((prev) => {
          if (prev === 1) {
            clearInterval(timer);
            setIsResendDisabled(false);
            return 30;
          }
          return prev - 1;
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [step, isResendDisabled]);

  const handleMobileSubmit = async () => {
    if (!mobile) {
      setErrors({ mobile: "Please enter a valid mobile number." });
      return;
    }

    try {
      setIsLoading(true);
      const response = await userLogin({ mobile });
      const message = response?.data?.success || "OTP sent successfully!";
      toast.success(message, { autoClose: 3000 });
      setStep(2);
      setIsResendDisabled(true);
    } catch (error) {
      const errorMessage =
        error.response?.data?.error ||
        error.response?.data?.message ||
        "Failed to send OTP. Please try again.";
      toast.error(errorMessage, { autoClose: 3000 });
    } finally {
      setIsLoading(false);
    }
  };

  const handleOtpSubmit = async () => {
    if (otp.length !== 6) {
      setErrors({ otp: "Please enter the complete 6-digit OTP." });
      return;
    }

    try {
      setIsLoading(true);
      const response = await verifyOTP({ mobile, otp });

      if (response?.data?.error) {
        throw new Error(response.data.error);
      }

      if (response?.data?.success) {
        toast.success(response.data.success, { autoClose: 3000 });

        const res = await userLogin({ mobile });
        dispatch({ type: "CLEAR_VERIFY_USER" });
        dispatch(
          logIn({
            auth_token: res?.headers?.x_auth_token,
            user_id: res?.data?.user?.id,
            user_type: res?.data?.user?.user_type,
          })
        );
        navigate("/");
        props.onHide();
      } else {
        throw new Error("Unexpected response format.");
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.error?.toString() ||
        error.message ||
        "Invalid OTP. Please try again.";

      setErrors({ otp: errorMessage });

      toast.error(errorMessage, { autoClose: 3000 });
    } finally {
      setIsLoading(false);
    }
  };

  const handleResendOtp = async () => {
    try {
      setIsLoading(true);
      const response = await resendOTP({ mobile });
      const message = response?.data?.success || "OTP resent successfully!";
      toast.success(message, { autoClose: 3000 });
      setIsResendDisabled(true);
      setOtp("");
    } catch (error) {
      const errorMessage =
        error.response?.data?.error ||
        error.response?.data?.message ||
        "Failed to resend OTP. Please try again.";
      toast.error(errorMessage, { autoClose: 3000 });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      {...props}
      backdrop="static"
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="profile_modal"
    >
      <Modal.Body className="profile_pop_up_wrape">
        {props?.show ? (
          <Container>
            <Row>
              <Col sm={12} lg={12}>
                <div className="profile_content">
                  <p className="text-end p-1 m-0">
                    <MdClose
                      fontSize="24px"
                      className="closeIcon"
                      onClick={props.onHide}
                    />
                  </p>
                  <div className="text-center">
                    <img src={logo} alt="logo" className="img-fluid rounded" />
                  </div>
                  {step === 1 ? (
                    <Form>
                      <Form.Group className="mb-4">
                        <Form.Label>Mobile Number*</Form.Label>
                        <PhoneInput
                          name="mobile"
                          country={"ae"}
                          value={mobile}
                          onChange={(value) => {
                            setMobile("+" + value);
                            setErrors({ ...errors, mobile: "" });
                          }}
                          inputClass="form-control"
                          specialLabel=""
                        />
                        <p className="mt-1 text-danger form_error_msg">
                          {errors?.mobile}
                        </p>
                      </Form.Group>
                      <button
                        type="button"
                        className="btn btn_style2"
                        onClick={handleMobileSubmit}
                        disabled={isLoading}
                      >
                        {isLoading ? "Sending OTP..." : "Send OTP"}
                      </button>
                    </Form>
                  ) : (
                    <Form>
                      <Form.Group className="mb-4">
                        <Form.Label>Enter 6-Digit OTP*</Form.Label>
                        <OtpInput
                          value={otp}
                          onChange={(value) => {
                            setOtp(value);
                            setErrors({ ...errors, otp: "" });
                          }}
                          containerStyle={
                            "d-flex justify-content-center justify-content-md-between otp_input_wrape"
                          }
                          skipDefaultStyles={false}
                          numInputs={6}
                          renderSeparator={<span></span>}
                          inputStyle={{
                            width: "50px",
                            height: "50px",
                            margin: "0 5px",
                            fontSize: "18px",
                            borderRadius: "4px",
                            border: "1px solid #ced4da",
                            textAlign: "center",
                          }}
                          renderInput={(props) => (
                            <input
                              {...props}
                              type="tel"
                              inputMode="numeric"
                              pattern="[0-9]*"
                            />
                          )}
                        />
                        <p className="mt-1 text-danger form_error_msg">
                          {errors?.otp}
                        </p>
                      </Form.Group>
                      <button
                        type="button"
                        className="btn btn_style2"
                        onClick={handleOtpSubmit}
                        disabled={isLoading}
                      >
                        {isLoading ? "Verifying..." : "Verify OTP"}
                      </button>
                      <p className="mt-3">
                        {isResendDisabled ? (
                          <span>Resend OTP in {resendTimer} seconds</span>
                        ) : (
                          <button
                            type="button"
                            className="btn btn-link text-black"
                            onClick={handleResendOtp}
                            disabled={isLoading}
                          >
                            Resend OTP
                          </button>
                        )}
                      </p>
                    </Form>
                  )}
                  {step === 1 && <p className="pop_up_breaker">Or</p>}
                  {step === 1 && (
                    <SocialLoginComponent
                      lg={12}
                      hideLoginPopUp={props?.onHide}
                    />
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        ) : null}
      </Modal.Body>
    </Modal>
  );
};

export default Login;
